<template>
  <!-- 品牌设计页面 -->
  <div class="index">
    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />
    <img class="banner" src="../imger/brand/banner.png" alt="LOGO设计"/>
    <ServerTop
      title="商标设计/LOGO及VI形象设计"
      wera="只做高端原创设计,定稿后免费申请版权保护"
      jiage="2380"
      qi="true"
    />
    <div class="box1">
      <div class="header">
        <div class="lan"></div>
        <p>服务详情</p>
      </div>
      <div class="title">
        <img src="../imger/AR/title.png" alt="商标设计-问标网" class="titletubiaoleft" />
        <p>专业设计</p>
        <img src="../imger/AR/title.png" alt="商标设计-问标网" class="titletubiaoright" />
      </div>
      <div class="word">
        <img src="../imger/brand/logo01.png" alt="LOGO设计" />
        <img src="../imger/brand/logo02.png" alt="包装设计" />
        <img src="../imger/brand/logo03.png" alt="画册设计" />
        <img src="../imger/brand/logo04.png" alt="VI设计" />
      </div>
    </div>

    <div class="box2">
      <div class="title">
        <img src="../imger/AR/title.png" alt="商标设计-问标网" class="titletubiaoleft" />
        <p>“10+6”系统成就品牌强大形象</p>
        <img src="../imger/AR/title.png" alt="商标设计-问标网" class="titletubiaoright" />
      </div>
      <div class="box2title">
        <p class="titleword">品牌形象十大平面系统</p>
      </div>
      <div class="box2wear">
        <div class="left">
          <div class="leftfield" v-for="(item, index) in left" :key="index">
            <img class="tb" src="../imger/brand/tb.png" alt="品牌形象十大平面系统"/>
            <p class="leftfieldnum">{{ index + 1 }}</p>
            <p>{{ item }}</p>
          </div>
        </div>
        <img src="../imger/brand/shewang.png" alt="LOGO设计" class="shewang" />
        <div class="left right">
          <div class="leftfield" v-for="(item, index) in right" :key="index">
            <img class="tb" src="../imger/brand/tb.png" />
            <p class="leftfieldnum">{{ index + 1 }}</p>
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="box3">
        <div class="box2title">
          <p class="titleword"> 品牌形象六大空间系统</p>
        </div>
        <div class="bg2">
          <p id="bg201">形象专卖店设计</p>
          <p id="bg202">环境导视系统设计</p>
          <p id="bg203">展厅与会展展台设计</p>
          <p id="bg204">办公空间形象设计</p>
          <p id="bg205">品牌连锁店终端设计</p>
          <p id="bg206">商业综合体空间设计</p>
        </div>
      </div>
    </div>

    <div class="bottom"></div>
    <div class="box4">
      <div class="title">
        <img src="../imger/AR/title.png" alt="商标设计-问标网" class="titletubiaoleft" />
        <p>产品优势</p>
        <img src="../imger/AR/title.png" alt="商标设计-问标网" class="titletubiaoright" />
      </div>
      <img src="../imger/brand/jiazhi.png" id="jiazhi" alt="">
      <div class="box4word01">
        <span>商业价值</span>
        <span class="box4wordwera">品牌是企业的无形资产，品牌价值就是真金白银</span>
      </div>
      <div class="box4word02">
        <span>企业价值</span>
        <span class="box4wordwera">企业经营的核心成果是品牌，品牌是市场对于企业认知和尊重的表现</span>
      </div>
      <div class="box4word03">
        <span>产品价值</span>
        <span class="box4wordwera">品牌是区别于同类竞品的重要标志，好的品牌意味着产品溢价空间</span>
      </div>
    </div>
    <div class="box4">
        <div class="title">
        <img src="../imger/AR/title.png" alt="商标设计-问标网" class="titletubiaoleft" />
        <p>服务案例</p>
        <img src="../imger/AR/title.png" alt="商标设计-问标网" class="titletubiaoright" />
      </div>
      <img src="../imger/brand/anli.png" id="anli" alt="商标设计-专业品牌设计公司">
    </div>
    <div class="box4">
      <ServerFoot :data="serverData" :show="true"/>
      <ServerCl :show="false" :Cl="Cl"/>
    </div>
    
    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";

import Title from "../components/trademarkRegistration/title.vue";
import ServerTop from "../components/serverTop.vue";
import ServerFoot from "../components/serverFoot.vue";
import ServerCl from "../components/serverCl.vue";
export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
    Title,
    ServerTop,
    ServerFoot,
    ServerCl,
  },
  data() {
    return {
      Cl:["设计稿源文件（电子版）","定稿LOGO图原创版权证书"],
      left: [
        "视觉识别策略",
        "品牌改造设计",
        "企业logo设计",
        "品牌logo设计",
        "超级IP及吉祥物体系",
      ],
      right: [
        "视觉识别策略",
        "品牌改造设计",
        "企业logo设计",
        "品牌logo设计",
        "超级IP及吉祥物体系",
      ],
      serverData:[
        {
          title:'需求沟通',
          content:'在线咨询客服，会安排专业设计顾问及时电话联系您，充分沟通确认设计需求。'
        },
        {
          title:'签合同,付定金',
          content:'充分沟通确认需求后，双方签订设计服务合同，约定交付时间，甲方支付50%定金后设计团队开始创意设计。'
        },
        {
          title:'提交设计初稿',
          content:'设计团队根据约定时间内提供设计初稿提案，甲方可根据提案提出合理的修改建议，设计团队全力配合修改。'
        },
        {
          title:'定终稿，付尾款',
          content:'设计团队根据甲方建议修改到满意为止，甲方定稿后支付剩余尾款，设计团队发送设计源文件并申请版权保护。'
        },
        {
          title:'服务完成',
          content:'约1-2个月版权局下发图形原创版权证书，至此服务完成。'
        },
      ]
    };
  },
};
</script>

<style scoped lang="scss">
.index {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner {
    width: 100%;
    height: 28.75rem /* 460px -> 28.75rem */;
  }
  .box1 {
    width: 75rem /* 1200px -> 75rem */;
    font-size: 1.625rem /* 26px -> 1.625rem */;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .header {
      width: 100%;
      height: 3.125rem /* 50px -> 3.125rem */;
      padding: 0.625rem /* 10px -> 0.625rem */;
      box-sizing: border-box;
      font-size: 1.25rem /* 20px -> 1.25rem */;
      color: #333333;
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      .lan {
        width: 0.3125rem /* 5px -> 0.3125rem */;
        height: 1.6875rem /* 27px -> 1.6875rem */;
        margin-right: 0.875rem /* 14px -> 0.875rem */;
        background-color: #4d89f1;
      }
    }
    .title {
      width: 100%;
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3.125rem /* 50px -> 3.125rem */;
      .titletubiaoleft {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-right: 1.25rem /* 20px -> 1.25rem */;
      }
      .titletubiaoright {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-left: 1.25rem /* 20px -> 1.25rem */;
      }
    }
    .word {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 1.875rem /* 30px -> 1.875rem */;
      margin-bottom: 3.125rem /* 50px -> 3.125rem */;
      img {
        height: 11.25rem /* 180px -> 11.25rem */;
        width: 15.8125rem /* 253px -> 15.8125rem */;
      }
    }
  }
  .box2 {
    width: 100%;
    height: 80.3125rem /* 1285px -> 80.3125rem */;
    background-image: url("../imger/brand/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    .title {
      width: 100%;
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3.125rem /* 50px -> 3.125rem */;
      .titletubiaoleft {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-right: 1.25rem /* 20px -> 1.25rem */;
      }
      .titletubiaoright {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-left: 1.25rem /* 20px -> 1.25rem */;
      }
    }
    .box2title {
      display: flex;
      justify-content: center;
      .titleword {
        width: 19.5625rem /* 313px -> 19.5625rem */;
        height: 3.4375rem /* 55px -> 3.4375rem */;
        border-radius: 0.625rem /* 10px -> 0.625rem */;
        background-color: #f49441;
        font-size: 1.375rem /* 22px -> 1.375rem */;
        color: #fff;
        text-align: center;
        line-height: 3.4375rem /* 55px -> 3.4375rem */;
      }
    }
    .box2wear {
      width: 100%;
      height: 25.625rem /* 410px -> 25.625rem */;
      display: flex;
      justify-content: center;
      align-items: center;
      .left {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-right: 9.625rem /* 154px -> 9.625rem */;
        .leftfield {
          width: 100%;
          height: 3.125rem /* 50px -> 3.125rem */;
          font-size: 1.25rem /* 20px -> 1.25rem */;
          color: #333333;
          display: flex;
          position: relative;
          .tb {
            width: 41px;
            height: 2.625rem /* 42px -> 2.625rem */;
            position: absolute;
            left: 0;
            bottom: 0;
          }
          .leftfieldnum {
            font-size: 2.25rem /* 36px -> 2.25rem */;
            line-height: 1.5625rem /* 25px -> 1.5625rem */;
            color: #333333;
            margin-right: 2.1875rem /* 35px -> 2.1875rem */;
            z-index: 10;
          }
        }
      }
      .right {
        margin-left: 9.625rem /* 154px -> 9.625rem */;
        margin-right: 0;
      }
      .shewang {
        width: 13.75rem /* 220px -> 13.75rem */;
        height: 19.125rem /* 306px -> 19.125rem */;
      }
    }
    .box3 {
      width: 100%;
      height: 32.8125rem /* 525px -> 32.8125rem */;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      .bg2 {
        height: 19.375rem /* 310px -> 19.375rem */;
        width: 100%;
        background-image: url("../imger/brand/bg2.png");
        background-size: contain;
        background-repeat: no-repeat;
        font-size: 1.143125rem /* 18.29px -> 1.143125rem */;
        color: #333333;
        #bg201 {
          position: absolute;
          top: 12.3125rem /* 197px -> 12.3125rem */;
          left: 22.8125rem /* 365px -> 22.8125rem */;
        }
        #bg202 {
          position: absolute;
          top: 25.625rem /* 410px -> 25.625rem */;
          left: 35rem /* 560px -> 35rem */;
        }
        #bg203 {
          position: absolute;
          top: 16rem /* 256px -> 16rem */;
          left: 52.8125rem /* 845px -> 52.8125rem */;
        }
        #bg204 {
          position: absolute;
          top: 30.375rem /* 486px -> 30.375rem */;
          right: 52rem /* 832px -> 52rem */;
        }
        #bg205 {
          position: absolute;
          top: 26.75rem /* 428px -> 26.75rem */;
          right: 37.1875rem /* 595px -> 37.1875rem */;
        }
        #bg206 {
          position: absolute;
          top: 14.125rem /* 226px -> 14.125rem */;
          right: 30.875rem /* 494px -> 30.875rem */;
        }
      }
      .box2title {
        display: flex;
        justify-content: center;
        .titleword {
          width: 19.5625rem /* 313px -> 19.5625rem */;
          height: 3.4375rem /* 55px -> 3.4375rem */;
          border-radius: 0.625rem /* 10px -> 0.625rem */;
          background-color: #f49441;
          font-size: 1.375rem /* 22px -> 1.375rem */;
          color: #fff;
          text-align: center;
          line-height: 3.4375rem /* 55px -> 3.4375rem */;
        }
      }

      .bottom {
        width: 75rem /* 1200px -> 75rem */;
        height: 10.8125rem /* 173px -> 10.8125rem */;
        background-image: url("../imger/brand/bottom.jpg");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .bottom {
    width: 75rem /* 1200px -> 75rem */;
    height: 10.8125rem /* 173px -> 10.8125rem */;
    background-image: url("../imger/brand/bottom.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 3.125rem /* 50px -> 3.125rem */;
    margin-bottom: 3.125rem /* 50px -> 3.125rem */;
  }
  .box4{
    width: 75rem /* 1200px -> 75rem */;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .title {
      width: 100%;
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3.125rem /* 50px -> 3.125rem */;
      .titletubiaoleft {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-right: 1.25rem /* 20px -> 1.25rem */;
      }
      .titletubiaoright {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-left: 1.25rem /* 20px -> 1.25rem */;
      }
    }
    #jiazhi{
      width: 25.625rem /* 410px -> 25.625rem */;
      height: 24rem /* 384px -> 24rem */;
      margin-top: 3.125rem /* 50px -> 3.125rem */;
      margin-bottom: 3.125rem /* 50px -> 3.125rem */;
    }
    #anli{
      margin-top: 4.625rem /* 74px -> 4.625rem */;
      margin-bottom: 4.8125rem /* 77px -> 4.8125rem */;
    }
    .box4word01{
      position: absolute;
      left: 0;
      top: 15.625rem /* 250px -> 15.625rem */;
      width: 23rem /* 368px -> 23rem */;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 1.25rem /* 20px -> 1.25rem */;
      font-weight: bold;
      color: #333333;
      .box4wordwera{
        font-size: 1rem /* 16px -> 1rem */;
        color: #7C8BA7;
        font-weight:normal;
        margin-top: 0.625rem /* 10px -> 0.625rem */;
      }
    }
    .box4word02{
      position: absolute;
      right: 1.875rem /* 30px -> 1.875rem */;
      top: 8.125rem /* 130px -> 8.125rem */;
      width: 23rem /* 368px -> 23rem */;
      display: flex;
      flex-direction: column;
      font-size: 1.25rem /* 20px -> 1.25rem */;
      font-weight: bold;
      color: #333333;
      .box4wordwera{
        font-size: 1rem /* 16px -> 1rem */;
        color: #7C8BA7;
        font-weight:normal;
        margin-top: 0.625rem /* 10px -> 0.625rem */;
      }
    }
    .box4word03{
      position: absolute;
      right: 1.875rem /* 30px -> 1.875rem */;
      top: 26.25rem /* 420px -> 26.25rem */;
      width: 23rem /* 368px -> 23rem */;
      display: flex;
      flex-direction: column;
      font-size: 1.25rem /* 20px -> 1.25rem */;
      font-weight: bold;
      color: #333333;
      .box4wordwera{
        font-size: 1rem /* 16px -> 1rem */;
        color: #7C8BA7;
        font-weight:normal;
        margin-top: 0.625rem /* 10px -> 0.625rem */;
      }
    }
  }
}
</style>